<script lang="ts" setup>
import type { strapiBlock } from '~/interfaces/strapi.interface'

const props = defineProps<{
  blocks: {
    id: number
    __component: string
  }[]
}>()

const blockMatch = (block: strapiBlock) => {
  return block.__component
    .replace('blocks.', 'blocks-')
    .replace('templates.', 'blocks-')
}
</script>

<template>
  <div class="blocks-area">
    <component
      :is="blockMatch(block)"
      v-for="block in props.blocks"
      :key="block.id"
      :content="block"
    />
  </div>
</template>
